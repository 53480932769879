/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import ContentfulRichText from '../components/ContentfulRichText';
// eslint-disable-next-line import/no-cycle
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import { Hero, HeroHeading, ContentWrap } from '../components/Global';
import theme from '../styles/theme';
import SimplifyTeamWork from '../components/SimplifyTeamWork';

const HeroWrap = styled(Hero)`
  max-width: 600px;

  h1 {
    color: ${theme.textColor.darkGrayText};
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    line-height: 16.8px;
    color: ${theme.body.light};
  }
`;

const contentPageTemplate = ({
  data, location
}) => {
  const {
    title,
    seoTitle,
    seoDescription,
    subtitle,
    content
  } = data.contentfulPage;
  const seoMeta = {
    title: seoTitle || `${title} | Workast`,
    description: seoDescription ? seoDescription.seoDescription : subtitle
  };
  return (
    <Layout location={location}>
      <SEO
        title={seoMeta.title}
        description={seoMeta.description}
      />
      <Container>
        <HeroWrap>
          <HeroHeading>{title}</HeroHeading>
          {subtitle && (
            <p>{subtitle}</p>
          )}
        </HeroWrap>
        <ContentWrap mb="0">
          <ContentfulRichText richText={content} />
        </ContentWrap>
        <div className="section">
          <SimplifyTeamWork />
        </div>
      </Container>
    </Layout>
  );
};

contentPageTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      seoTitle: PropTypes.string,
      seoDescription: PropTypes.shape({
        seoDescription: PropTypes.string
      }),
      // eslint-disable-next-line react/forbid-prop-types
      content: PropTypes.object,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default contentPageTemplate;

export const pageQuery = graphql`
  query ContentPageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      slug
      title
      subtitle
      seoTitle
      seoDescription {
        seoDescription
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData
          }
          ... on ContentfulElementButton {
            __typename
            contentful_id
            buttonText: text
            link
          }
        }
      }
    }
  }
`;
