import React from 'react';
import PropTypes from 'prop-types';
import {
  CTASection,
  SectionTitle,
} from '../Global';
import SignUpModal from '../SignUpModal';


const SimplifyTeamWork = ({ style }) => (
  <CTASection maxWidth="560px" style={style}>
    <SectionTitle>
      Make teamwork simple with Workast
    </SectionTitle>
    <SignUpModal buttontext="Try it for free" />
  </CTASection>
);

SimplifyTeamWork.defaultProps = {
  style: {},
};
SimplifyTeamWork.propTypes = {
  style: PropTypes.shape({}),
};

export default SimplifyTeamWork;
